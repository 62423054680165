body, html {
  height: 100%;
}

.App {
  height: 100%;
}

* {
  font-family: bicyclette, sans-serif;
}

$blue: #090909;
$gold: #dba220;

.mobile {
  display: none;

  @media screen and (max-width: 1024px) {
    display: initial;
  }
}

.desktop {
  display: initial;

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.no-scroll {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }

  &::after {
    position: fixed;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    background: url(./assets/img/stars.webp);
    background-size: cover;
    mix-blend-mode: lighten;
    z-index: -0;
    content: '';
    pointer-events: none;

    @keyframes scaleBG {
      
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  padding: 0;
  margin: 0;
}

.slick-prev::before, .slick-next::before {
  font-size: 40px!important;
}

@mixin goldenBG {
  border: solid 1px rgba(black, 0.1);
  border-left: none;
  background: rgb(144,109,46);
  background: linear-gradient(90deg, rgba(144,109,46,1) 0%, rgba(226,189,129,1) 100%);
  border-radius: 5px;
  border-bottom: 2px solid #F7D99F;
  box-shadow: 0 2px 2px rgba(#ccc, .7);
  position: relative;
  color: white;
}